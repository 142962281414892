import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Dedicated Messaging App Developers | Offshore Messaging App Development Services from India',
    metaDescription: 'Our Messaging Application developer builds modern and intuitive iOS, Android, Native applications for your business at the lowest cost.',
    h1: 'Hire Dedicated Messaging App Developers',
    h1Subtext1: 'Connect Real-time, anywhere, anytime with the instant Messaging App Development.',
    h1Subtext2: 'MatteCurve is the best messaging app development company in India. We are a JavaScript company and have dedicated messaging app developers working remotely. We work with fortune 500 companies and startups to build end-to-end solutions based on the JavaScript ecosystem. Share your requirements and hire messaging app developers within 7 days.',
    aboutSection: {
        title: 'About Messaging App Development',
        subTitle: 'Messaging app is medium to communicate with single or group of people.',
        paragraph1: "Instant messaging apps have become one of the most powerful platforms for reaching out to customers on a daily or a monthly basis. They gave us an opportunity to deliver messages to our friends, families and colleagues.",
        paragraph2: "Social messaging apps have become a very important platform and can empower users not only for sending messages but also for exchanging real-time text messages, audio messages, transact video calling, share multimedia or even create chat groups and communities.",
        paragraph3: `
        Ideally a business needs a messaging app if they want to communicate with their customers and provide support.
        Developing a messaging app requires both front-end and back-end development.
        Hire messaging app developers from MatteCurve who are experienced and skilled in developing large scale messaging applications.
        `,
        keyPoints: [
            "Instant messaging apps connect anywhere, anytime, at any time.",
            "MatteCurve makes pertinent messaging applications which provide easy to use and a single option to users.",
            "We provide messaging and chatting applications  for exchanging real-time messaging apps such as WhatsApp, Telegram, Snapchat, hike applications for Diverse Business Needs.",
            "We delivered  robust, scalable, easy to access and realistic solutions that can help you to initiate  traffic towards your application.",
            "MatteCurve provides the powerful, intuitive and customizable app for external communications.",
            "To build a messaging app we need front-end developers and back-end developers."
        ],
    },
    statsSection: {
        paragraph: "Instant messaging apps are gaining a huge market day by day due to its functional-rich, user-friendly and typical mobile messaging featured application. Currently, 98% is the open rate of messaging for brand messages. Some examples of popular messaging apps include WhatsApp, Facebook Messenger, WeChat and QQ Messenger, Telegram, Viber, Line, and Snapchat. Hire Dedicated Messaging App Developers in India from MatteCurve to grow by 4X.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated Messaging App Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire dedicated Messaging App Developers?",
        "Hire dedicated Messaging App Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for Messaging App Development?",
        paragraph: "MatteCurve has been recognized as one of the leading Messaging App development companies in building high-performance Messaging App apps from India. MatteCurve offers you deep expertise in Messaging App web development and Messaging App app development, delivered by a team of highly-skilled Messaging App Developers with unmatched expertise in building progressive and dynamic web apps using Messaging App.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
